.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2em;
}

.search_input {
  border-radius: 20px;
  background-color: white;
  width: 100%;
}

.card {
  border-radius: 10px;
  width: 100%;
  margin: 1em;
  display: inline-flex;
}

.card_skeleton {
  border-radius: 10px;
  width: 100%;
  margin: 1em;
  height: 6em;
}

.card .card_content {
  flex: 2;
  padding-bottom: 0;
}

.card .info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  max-height: 6em;
  color: #8083a3;
  width: 100%;
}

.card .info p {
  margin-bottom: 1em;
  margin-right: 1em;
  min-width: 7em;
}

.card span {
  font-weight: 500;
}

.card .details_button {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}