/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

html,
body {
  margin: 0;
  height: 100%;
}

body {
  background-color: #f5f5f5;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  font-family: "Roboto", sans-serif;
}

a {
  cursor: pointer;
}

.success {
  color: #1d721c;
  background-color: #c2ffc6;
  border: 1px solid #63e12f;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
  width: 100%;
}

.error {
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
  width: 100%;
}

.warn {
  color: #ffc230;
  background-color: #ffd60233;
  border: 1px solid #fdd40833;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
  width: 100%;
}

.logo_img {
  width: 50px;
  margin-right: 1em;
}

/* form {
  max-width: 40%;
  margin: 0 auto;
}

form input {
  border: 1px solid;
  border-color: #b9b9b980;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 200;
  margin-bottom: 1em;
  padding: 0.5em;
  width: 100%;
} */

.button {
  border-radius: 109.804px;
  background: #461E5F;
  color: white;
}

.button:hover {
  background: #682c8d;
}

.button_transparent {
  border-radius: 109.804px;
  color: black;
  border-color: black;
}

.button_transparent:hover {
  background: #682c8d;
  color: white;
  border-color: transparent;
}

input::placeholder {
  color: #b9b9b9;
}

.divider {
  margin: 2em;
}

.card {
  border-radius: 20px;
}

.card_info {
  background-color: #fff;
  margin: 0 2%;
  border-radius: 25px;
  box-shadow: 5px 5px 5px darkgray;
  margin-bottom: 1em;
}

.available,
.unavailable,
.warning,
.ignored {
  display: inline-flex;
  border-radius: 30px;
  /* min-width: 7em !important;
  max-width: 7em; */
}

.available span,
.unavailable span,
.warning span,
.ignored span {
  display: inline-flex;
  margin: 0 auto;
  font-weight: normal !important;
}

.available {
  background-color: #3be2b233;
  color: #16c190;
}

.unavailable {
  background-color: #ec515433;
  color: #ec5154;
}

.warning {
  background-color: #ffd60233;
  color: #ffc230;
}

.ignored {
  border: solid;
  border-width: thin;
  border-color: black;
  color: black;
}

.ignored .css-i4bv87-MuiSvgIcon-root {
  width: 0.8em;
  height: 0.8em;
}

.recaptcha {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1em;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* 50% opacity black background */
  filter: grayscale(100%);
  /* Apply grayscale effect */
  z-index: 999;
  /* Adjust the z-index to ensure it's above everything except the header */
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2em;
}

.query-input {
  border-radius: 20px;
  background-color: white;
  width: 100%;
}

/* Rome arrows of Input Number */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.main_tab {
  border-radius: 306px;
  border: 1px solid #8083A3;
  color: #8083A3;
  margin-right: 0.5em;

  &.active {
    color: #fff;
    background: linear-gradient(90deg, #461E5F 0%, #5065DA 100%);
  }
}

.main_tab:hover {
  color: #fff;
  background: linear-gradient(90deg, #461E5F 0%, #5065DA 100%);
  transition: 0.7s;
}