.header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 1em auto;
}

.header_title {
  margin-bottom: 0;
}

.header_subtitle {
  font-weight: normal;
}

.body {
  display: flex;
  justify-content: space-evenly;
}

.card {
  border-radius: 25px;
  box-shadow: -10px 10px 10px darkgray;
  width: 4em;
  padding: 0.5em;
}

.icons {
  /* width: 50%;
  height: 50%; */
  margin: auto;
}

.iat_info {
  display: grid;
}