.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  bottom: 0;
  height: 250px;
  background-color: #000;
  color: #fff;
  position: relative;
  z-index: 1201;
}