.header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 1em auto;
}

.header_title {
  margin-bottom: 0;
}

.header_subtitle {
  font-weight: normal;
}

.card {
  background-color: #fff;
  margin: 0 2%;
  border-radius: 25px;
  box-shadow: 10px 10px 10px darkgray;
  margin-bottom: 1em;
  height: 30em;
}

.card a {
  height: inherit;
  /* padding-bottom: 5em; */
}

.title {
  color: #838383;
  text-align: start;
  margin: 7% 0% 3% 5%;
  height: 1em;
}

.description {
  padding: 0.5em;
  font-weight: 600;
  margin: 0 auto;
  height: 2.5em;
  color: #000;
}

.card_image {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2em;
}

.icons {
  width: 300px;
  height: 300px;
  margin: auto;
}

.carousel {
  /* padding-left: 1em;
  padding-right: 1em; */
}