.card_query {
  flex-direction: row;
  width: 100%;
  padding: 1em;
}

.form_query {
  flex-direction: row;
  width: 100%;
}

.info_div {
  width: 100%;
  margin-top: 1em;
}

.main_button {
  border-radius: 306px;
  border: 1px solid #8083A3;
  color: #8083A3;
  margin-right: 0.5em;

  &.active {
    color: #fff;
    background: linear-gradient(90deg, #461E5F 0%, #5065DA 100%);
  }
}

.main_button:hover {
  color: #fff;
  background: linear-gradient(90deg, #461E5F 0%, #5065DA 100%);
  transition: 0.7s;
}

.title {
  margin-top: 0;
}