.header {
  display: flex;
  margin-top: 0.5em;
}

.card_header {
  border-radius: 20px;
  background-color: #F2F4FC;
  margin-right: 1em;
  padding: 0.5em;
}

.card_header_content {
  display: flex;
  align-items: center;
}

.result_table {
  border-radius: 20px;
  margin-top: 1em;
}