.main {
  padding: 1em;
}

.card {
  border-radius: 20px;
}

.net_info div[role=tabpanel] {
  height: 29em;
}

.wan_lan {
  display: flex;
}

.net_info .wan_lan .content {
  width: 50%;
}

.title {
  font-weight: bold;
}

.params {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 24em;
}

.params div {
  /* display: flex;
  align-items: baseline; */
  margin: 0.5em 0;
  font-weight: bold;
  color: #8083a3;
}

.params div p {
  font-weight: normal;
  margin: 0;
}

.params label {
  align-items: flex-start;
  width: fit-content;
}

.params label span {
  margin-left: 0;
}

.params span {
  font-weight: normal;
  color: #8083a3;
  margin-left: 0.5em;
}

.wifi {
  margin-top: auto;
}

.params_wifi div {
  display: flex;
  font-weight: bold;
  color: #8083a3;
  margin: 0.5em 0;
}

.params_wifi div span {
  font-weight: normal;
  margin-left: auto;
}

.device_info {
  min-height: 42.7em;
}

.cpe_info {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 20px;
}

.cpe_status {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 5em;
  padding: 0.6em
}

.cpe_info label {
  color: #8083A3;
  font-size: small;
}

.cpe_status span {
  min-width: 5em;
  font-weight: bold;
}

.cpe_info span {
  color: #1F1F1F;
  /* font-size: large; */
  font-weight: bold;
}

.cpe_bottom {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.cpe_bottom .version {
  background-color: #E9E9E9;
  border-radius: 45px;
  padding: 0.2em 1em;
}

.label_inline {
  display: inline;
}

.wifi_header {
  display: flex;
  justify-content: space-between;
}

.button {
  background-color: #461e5f;
  color: white;
  border-radius: 110px;
  margin-left: auto;
}

.hidden {
  display: none;
}

.button:hover {
  background-color: #5d277e;
}

.cpe_date {
  color: #B9B9B9;
  font-size: small;
  text-align: end;
}

.divider {
  margin: 1em 0;
}

.ping_statistics {
  margin-top: 2em;
  display: flex;
  justify-content: space-around;
}

.ping_statistics_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 4em;
}

.ping_info {
  display: inline-flex;
  background: rgba(185, 185, 185, 0.50);
  border-radius: 100px;
  width: 8em;
  font-weight: bold;
}

.ping_statistics_div span {
  margin: 0 auto;
}

.ping_min {
  display: inline-flex;
  border-radius: 30px;
  background-color: #16c19033;
  color: #16c190;
  width: 8em;
}

.ping_max {
  display: inline-flex;
  border-radius: 30px;
  background-color: #ec515433;
  color: #ec5154;
  width: 8em;
}

.ping_media {
  display: inline-flex;
  border-radius: 30px;
  background-color: #ffd60233;
  color: #ffc230;
  width: 8em;
}

.modal {
  display: flex;
  flex-direction: column;
}