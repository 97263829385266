.nav {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #461E5F 0%, #5065DA 100%);
  display: flex;
  align-items: center;
  /* width: 100vw; */
}

.nav ul {
  display: flex;
}

.nav li {
  display: flex;
  align-items: center;
  margin-right: 1em;
  cursor: pointer;

}

.nav li a {
  color: #fff;
  text-decoration: none;
  padding: 0.4em 0.6em;
}

.nav li a:hover {
  color: #9b9b9b;
}

.logOut {
  width: 10%;
  font-size: 1em;
  background: none;
  border: 0;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
}

div .line1 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, rgba(70, 30, 95, 0.70) 0%, rgba(80, 101, 218, 0.70) 100%);
  padding: 0.2em;
}

div .line2 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, rgba(70, 30, 95, 0.50) 0%, rgba(80, 101, 218, 0.50) 100%);
  padding: 0.2em;
}

div .user_profile_container {
  display: flex;
  align-items: center;
  margin-left: auto;
}

li .user_profile_container {
  display: flex;
  align-items: center;
  margin-left: auto;
}