.result_table {
  border-radius: 20px;
  margin-top: 1em;
}

.header {
  display: flex;
  margin-top: 0.5em;
}

.card_header {
  border-radius: 20px;
  background-color: #F2F4FC;
  margin-right: 1em;
}

.card_header_content {
  display: flex;
  align-items: center;
}

.button_header {
  border-radius: 20px;
  background-color: #F2F4FC;
  margin-right: 0.5em;
}

.column_header {
  background-color: rgba(128, 131, 163, 0.05);
}

.column_header th {
  font-size: large;
}

/* .config_icons img {
  width: 4em;
} */