.menu ul {
  display: flex;
}

/* .menu ul div {
  border-right-color: #b9b9b980;
  border-right-width: thin;
  border-right-style: solid;
} */

.menu_category {
  font-weight: bolder;
}

.highlight {
  border-radius: 161.111px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #461E5F 0.22%, #5065DA 99.79%);
  color: white;
  margin-left: 0.5em;
  padding: 0.2em 0.5em;
  font-size: small;
}