.menu {
  display: flex;
  align-items: center;
  text-align: center;
}

.welcome {
  display: flex;
  flex-direction: column;
  color: #fff;
  font-size: small;
}

.welcome p {
  font-weight: bold;
  margin: 0;
}