.gallery {
  display: flex;
  align-items: center;
  justify-content: center;
}

.gallery img {
  cursor: pointer;
  width: 200px;
}

.img_hidden {
  display: none;
}