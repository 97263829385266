.main_button {
  border-radius: 306px;
  border: 1px solid #8083A3;
  color: #8083A3;
  margin-right: 0.5em;

  &.active {
    color: #fff;
    background: linear-gradient(90deg, #461E5F 0%, #5065DA 100%);
  }
}

.main_button:hover {
  color: #fff;
  background: linear-gradient(90deg, #461E5F 0%, #5065DA 100%);
  transition: 0.7s;
}

.card {
  border-radius: 20px;
  /* min-height: 500px; */
}

.sticky_card {
  border-radius: 20px;
  /* min-height: 500px; */
  position: sticky;
  top: 5em;
  overflow-y: auto;
}

.result_table {
  border-radius: 20px;
  margin-top: 1em;
}

.params {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.params span {
  font-weight: bold;
}

.summary_info_row hr {
  margin-right: 1em;
}